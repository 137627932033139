import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router'
import { SuperTokensAuthService } from '../services/supertokens-auth.service'

export async function isAuthenticatedGuard(
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<boolean> {
  const authService = inject(SuperTokensAuthService)
  const router = inject(Router)

  const doesSessionExist = await authService.checkForSession()
  if (!doesSessionExist) {
    await router.navigate(['/login'])
    return false
  }

  // Allow access to all authenticated routes
  if (route.routeConfig?.path === '**' && doesSessionExist) {
    await router.navigate(['/notfound'])
    return false
  }

  return true
}
