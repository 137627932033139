import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { PrimeNGConfig } from 'primeng/api'
import { environment } from '../environments/environment'
import * as SuperTokens from 'supertokens-web-js'
import * as Session from 'supertokens-web-js/recipe/session'
import * as EmailPassword from 'supertokens-web-js/recipe/emailpassword'
import { SuperTokensAuthService } from './shared/services/supertokens-auth.service'

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private userInfoFetched = false
  private router = inject(Router)
  private primengConfig = inject(PrimeNGConfig)
  private superTokensAuthService = inject(SuperTokensAuthService)

  async init(): Promise<void> {
    this.primengConfig.ripple = true

    console.log('pase init')

    SuperTokens.init({
      appInfo: {
        apiDomain: environment.BASE_URL,
        apiBasePath: '/auth',
        appName: '...',
      },
      recipeList: [Session.init(), EmailPassword.init()],
    })

    const doesSessionExist = await this.superTokensAuthService.checkForSession()

    if (doesSessionExist) {
      this.superTokensAuthService.getUserInfo()
    }
  }
}
