import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import * as Session from 'supertokens-web-js/recipe/session'

@Injectable({
  providedIn: 'root',
})
export class SuperTokensAuthService {
  private hasSessionSubject = new BehaviorSubject<boolean>(false)
  hasSession$ = this.hasSessionSubject.asObservable()

  private userInfoSubject = new BehaviorSubject<any>(null)

  private httpClient = inject(HttpClient)

  async checkForSession(): Promise<boolean> {
    const doesSuperTokensSessionExist = await Session.doesSessionExist()
    this.hasSessionSubject.next(doesSuperTokensSessionExist)
    return doesSuperTokensSessionExist
  }

  signOut(): Promise<void> {
    return Session.signOut().then(() => {
      this.hasSessionSubject.next(false)
      this.userInfoSubject.next(null) // Clear user info on sign out
      window.location.href = '/login'
    })
  }

  getUserInfo(): any {
    const url = environment.BASE_URL
    return this.httpClient.get(`${url}/user-metadata`).subscribe({
      next: (data) => {
        this.userInfoSubject.next(data)
      },
      error: (error) => console.error('Failed to fetch user info', error),
    })
  }

  updateUserInfo(payload: any): Observable<any> {
    const url = environment.BASE_URL
    return this.httpClient.patch(`${url}/user-metadata`, payload)
  }

  get userInfo$(): Observable<any> {
    return this.userInfoSubject.asObservable()
  }
}
