import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core'
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router'

import { routes } from './app.routes'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClient } from '@angular/common/http'
import { HttpFetchBackend } from './shared/services/http-fetch-backend.service'
import { AppInitService } from './app-init.service'

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules)),
    importProvidersFrom(BrowserAnimationsModule),
    {
      provide: HttpClient,
      useFactory: (backend: HttpFetchBackend) => new HttpClient(backend),
      deps: [HttpFetchBackend],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => () =>
        appInitService.init(),
      deps: [AppInitService],
      multi: true,
    },
  ],
}
