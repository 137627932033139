import { Routes } from '@angular/router'
import { isAuthenticatedGuard } from './shared/guards/auth.guard'
import { isAdmingGuard } from './shared/guards/admin.guard'
import { LoginComponent } from './core/auth/login/login.component'
import { AppLayoutComponent } from './layout/app.layout.component'
import { NotfoundComponent } from './shared/components/notfound/notfound.component'
export const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [isAuthenticatedGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/dashboard/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/comparision/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/stores/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/productList/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/zones/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/reports/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/mappers/routes').then((m) => m.routes),
        canActivate: [isAuthenticatedGuard, isAdmingGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/alerts/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/settings/routes').then((m) => m.routes),
      },
      {
        path: '',
        loadChildren: () =>
          import('./features/analytics-dashboard/routes').then((m) => m.routes),
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'notfound',
    component: NotfoundComponent,
  },
  {
    path: '**',
    canActivate: [isAuthenticatedGuard],
    component: NotfoundComponent,
  },
]
