import { Component, ElementRef, ViewChild } from '@angular/core'
import { LayoutService } from './service/app.layout.service'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { AppMenuComponent } from './app.menu.component'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-sidebar',
  templateUrl: './app.sidebar.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, AppMenuComponent],
})
export class AppSidebarComponent {
  timeout: any = null
  environmentName: string

  @ViewChild('menuContainer') menuContainer!: ElementRef
  constructor(public layoutService: LayoutService, public el: ElementRef) {
    this.environmentName = environment.environmentName
  }

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.layoutService.state.sidebarActive = true
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(
          () => (this.layoutService.state.sidebarActive = false),
          300
        )
      }
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored
  }
}
