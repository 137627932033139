import {Injectable, inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {SuperTokensAuthService} from "../services/supertokens-auth.service";
import { isAdmin } from '../utils/utils';

export async function isAdmingGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const authService = inject(SuperTokensAuthService);
    const router = inject(Router);

    return isAdmin()
  }