import { Component, inject } from '@angular/core'
import { LayoutService } from './service/app.layout.service'
import { CommonModule } from '@angular/common'
import { SidebarModule } from 'primeng/sidebar'
import { BadgeModule } from 'primeng/badge'
import { RouterModule } from '@angular/router'
import { SuperTokensAuthService } from '../shared/services/supertokens-auth.service'

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html',
  standalone: true,
  imports: [CommonModule, SidebarModule, BadgeModule, RouterModule],
})
export class AppProfileSidebarComponent {
  userInfo: any
  private authService = inject(SuperTokensAuthService)
  public layoutService = inject(LayoutService)

  constructor() {
    this.authService.userInfo$.subscribe({
      next: (value) => {
        this.userInfo = value
      },
    })
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val
  }

  signOut() {
    this.authService.signOut()
  }
}
