import { AvatarModule } from 'primeng/avatar'
import { CommonModule } from '@angular/common'
import { Component, ElementRef, ViewChild, inject } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { LayoutService } from 'src/app/layout/service/app.layout.service'
import { AppBreadcrumbComponent } from './app.breadcrumb.component'
import { FormsModule } from '@angular/forms'
import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { SuperTokensAuthService } from '../shared/services/supertokens-auth.service'

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    AppBreadcrumbComponent,
    FormsModule,
    ButtonModule,
    InputTextModule,
    AvatarModule,
  ],
})
export class AppTopbarComponent {
  @ViewChild('menubutton') menuButton!: ElementRef
  initials: any
  private authService = inject(SuperTokensAuthService)
  public layoutService = inject(LayoutService)

  constructor() {
    this.getUserData()
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle()
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar()
  }
  onConfigButtonClick() {
    this.layoutService.showConfigSidebar()
  }

  getUserData(): void {
    this.authService.userInfo$.subscribe({
      next: (data) => {
        if (data) {
          this.initials = this.getInitials(data.firstName, data.lastName)
        }
      },
      error: () => {
        console.error('Failed to get user data')
      },
    })
  }

  getInitials(firstName: string, lastName: string): string {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
  }
}
