<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <div class="flex flex-column align-items-center">
            <a [routerLink]="['/']" class="app-logo">
                <img style="width: 5rem;" src="assets/demo/images/logosmall.png" alt="">   </a>
                {{environmentName}}
        </div>
        <button class="layout-sidebar-anchor p-link z-2 mb-2" type="button" (click)="anchor()"></button>
    </div>


    <div #menuContainer class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>